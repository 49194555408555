<template>
  <div>
    <nav class="header-navbar w-100">
      <div
        class="navbar-header h-100"
        style="z-index: 1;"
      >
        <!-- Logo -->
        <b-link
          class="navbar-brand h-100"
          to="/"
        >
          <span class="brand-logo ml-2 pl-1 h-100">
            <b-img
              class="h-100 w-100"
              :src="require('@/assets/images/logo/TraceBit-logo.svg')"
              alt="Logo"
            />
          </span>
        </b-link>
        <div class="nav-right h-100 d-flex mr-2 pr-1">
          <b-link to="/login">
            <span>Log in</span>
          </b-link>
          <b-link to="/register">
            <span>Sign up</span>
          </b-link>
        </div>
      </div>
    </nav>
    <div class="app-content content ml-0 pb-2 text-justify">
      <b-card>
        <div class="p-2">
          <h2>Syarat dan Ketentuan</h2>
          <br>
          <!-- Content Privacy Policy -->
          <p>Selamat datang di TraceBit,</p>
          <p>
            Sebelum Pengguna mendaftar, menggunakan layanan TraceBit dan/atau menerima konten yang terdapat di dalamnya, Pengguna harus membaca, memahami, menerima dan menyetujui semua persyaratan dan ketentuan ini karena ini merupakan perjanjian yang mengikat antara Pengguna dan TraceBit.
          </p>
          <p>
            Dengan mengakses atau menggunakan situs <b><i>www.tracebit.net</i></b>, pengguna dianggap telah memahami dan menyetujui semua isi dalam Syarat dan Ketentuan di bawah ini. Syarat dan Ketentuan dapat diubah atau diperbaharui sewaktu-waktu tanpa ada pemberitahuan terlebih dahulu. Perubahan dari Syarat dan Ketentuan akan segera berlaku setelah dicantumkan di dalam situs TraceBit.
          </p>
          <p>
            Jika Pengguna mendaftar untuk uji coba gratis atau untuk layanan gratis TraceBit, maka Syarat dan Ketentuan ini juga akan mengatur uji coba gratis atau layanan gratis tersebut.
          </p>
          <ol type="1">
            <li>
              Ketentuan Penggunaan Situs
              <br>
              Saat mengunjungi dan menggunakan situs dan setiap fitur layanan TraceBit, setiap pengguna wajib untuk mematuhi Ketentuan Penggunaan Situs berikut ini:
              <ol type="a">
                <li>Pengguna minimum berusia 18 tahun, tidak berada di bawah perwalian atau pengampuan, sehingga Pengguna secara hukum memiliki kapasitas dan berhak mengikatkan diri pada Syarat dan Ketentuan layanan ini;</li>
                <li>Pengguna tidak diperkenankan untuk mereproduksi, mendistribusikan, memajang, menjual, menyewakan, mengirimkan, membuat karya turunan dari, menerjemahkan, memodifikasi, merekayasa balik, membongkar, mengurai atau mengeksploitasi situs TraceBit;</li>
                <li>Pengguna tidak diperkenankan untuk membuat dan menerbitkan konten yang melanggar hak cipta, paten, merek dagang, merek layanan, rahasia dagang, atau hak kepemilikan lainnya;</li>
                <li>Pengguna dilarang mempergunakan situs TraceBit untuk <i>scamming</i>, produk atau jasa yang tidak memiliki izin beroperasi berdasarkan peraturan pemerintah Indonesia, seperti namun tidak terbatas pada asuransi, investasi, judi, uang kripto (<i>cryptocurrency</i>), <i>blockchain</i>, <i>leasing</i>, saham, <i>trading</i>;</li>
                <li>Pengguna hanya diperbolehkan untuk menggunakan foto, video, dan/atau aset desain lain yang berlisensi atau dimiliki sepenuhnya oleh Pengguna dan tidak melanggar hukum;</li>
                <li>Pengguna dilarang mengirimkan atau mengunggah konten yang berbau <i>scrapping</i> atau aktivitas pengambilan data dari situs TraceBit tanpa seizin pemilik data;</li>
                <li>Dalam penggunaan layanan dan situs TraceBit, Pengguna dilarang menggunakan konten yang melanggar hukum seperti judi, organisasi yang melanggar hukum, menyinggung SARA, pornografi, konten dewasa, ancaman, kekerasan, memfitnah golongan, menghasut, dan obat-obatan yang tidak berijin BPOM dan menyinggung beberapa golongan atau untuk tujuan-tujuan tertentu yang bisa menimbulkan segala kewajiban Hukum Perdata atau Pidana Indonesia atau Hukum Internasional.</li>
              </ol>
            </li>
            <li>Penggunaan Cookies
              <ol type="a">
                <li><i>Cookies</i> merupakan file teks berisi potongan kecil data yang tersimpan di komputer untuk membantu menyimpan preferensi dan informasi lain yang digunakan, serta rekam jejak di halaman web yang dikunjungi Pengguna. Namun, dalam beberapa kasus, <i>cookie</i> hanya berisi informasi tentang pengaturan tertentu yang tidak dapat dikaitkan dengan orang tertentu. TraceBit menggunakan <i>cookie</i> di situs TraceBit;</li>
                <li>TraceBit menggunakan <i>cookie</i> untuk melacak aktivitas Pengguna dengan tujuan untuk meningkatkan pelayanan dan kepuasan Pengguna dalam memakai layanan TraceBit;</li>
                <li>Dalam hal-hal tertentu <i>cookie</i> dapat berdampak pada masalah <i>login</i> ke akun TraceBit Pengguna, apabila hal ini terjadi Pengguna dapat menghapus <i>cache</i> dan <i>cookie</i> yang disimpan di <i>browser</i> Pengguna;</li>
                <li>Pengguna dapat menonaktifkan <i>cookie</i> melalui pengaturan <i>browser</i> Pengguna, namun hal tersebut mungkin dapat berdampak pada tidak dapat diaksesnya beberapa fitur di situs TraceBit.</li>
              </ol>
            </li>
            <li>
              Pendaftaran Akun
              <ol type="a">
                <li>Untuk sementara dan sampai pada waktu yang ditentukan pendaftaran dan/atau pembukaan akun di situs TraceBit hanya dapat dilakukan dengan mengisi form registrasi yang akan disetujui oleh TraceBit.</li>
                <li>Calon Pengguna akan diminta untuk memberikan data pribadi dan informasi lain yang dibutuhkan saat melakukan pendaftaran pertama kali di situs TraceBit seperti (namun tidak terbatas pada):
                  <ul class="dash">
                    <li>Nama;</li>
                    <li>Nama Perusahaan;</li>
                    <li>Alamat Perusahaan (bila ada);</li>
                    <li>Email aktif;</li>
                    <li>Nomor Handphone aktif;</li>
                    <li>NPWP (bila ada).</li>
                    <li>Ijin seperti nomor BPOM, Hak atas Kekayaan Intelektual, surat ijin dari OJK, dan lain-lain apabila diperlukan.</li>
                  </ul>
                </li>
                <li>Calon Pengguna harus memastikan data, informasi, dan/atau keterangan yang diberikan adalah data atau informasi yang benar dan akurat, dan wajib menginformasikan kembali kepada TraceBit jika terdapat perubahan pada data atau informasi tersebut;</li>
                <li>Sebelum mendaftar, Calon Pengguna wajib memahami <b>Ketentuan Akun</b> serta aturan lain yang tertulis dalam halaman Syarat dan Ketentuan ini.</li>
              </ol>
            </li>
            <li>Ketentuan Akun
              <ol type="a">
                <li>Setiap data atau informasi pribadi yang dikirimkan sehubungan dengan Pengguna atas penggunaan layanan, termasuk melalui penggunaan fitur apapun di situs ini tunduk pada Kebijakan Privasi TraceBit;</li>
                <li>Dalam penggunaan layanan dan situs TraceBit, pengguna tidak diperbolehkan mendaftarkan diri dengan mengatasnamakan perusahaan lain yang bukan merupakan perwakilan sah, tanpa adanya izin tertulis dari perusahaan tersebut;</li>
                <li>TraceBit berhak menolak permohonan pendaftaran akun atau akses akun yang dianggap melanggar aturan, berbahaya, dan tidak sesuai ketentuan. Apabila penolakan ini terjadi, maka Pengguna  tidak dapat masuk (<i>login</i>) ke akun dan tidak dapat menggunakan fitur dan layanan TraceBit;</li>
                <li>Apabila Pengguna sudah melakukan pembayaran dan TraceBit melakukan penolakan pendaftaran atau akses akun, dana tersebut akan dikembalikan;</li>
                <li>Setiap akun dalam layanan digunakan secara eksklusif dan semata-mata milik Pengguna dari akun tersebut. Demi keamanan, Pengguna wajib menjaga kerahasiaan informasi akun dan tidak mengungkapkannya kepada orang atau entitas manapun;</li>
                <li>Pengguna tidak dapat merubah sendiri email yang didaftarkan sebagai pengguna TraceBit. Perubahan data untuk sementara dapat dilakukan dengan menghubungi pihak TraceBit sampai Syarat dan Ketentuan ini diubah;</li>
                <li>Pengguna dilarang untuk menambahkan kontak email lain atau milik pihak ketiga yang belum memiliki izin dari pemilik email tersebut;</li>
                <li>Pengguna tidak diperkenankan menjual, berupaya menjual, menawarkan untuk menjual, mengalihkan, menyewakan atau dengan cara lain memindahkan akun dan sandi mereka kepada pihak ketiga mana pun;</li>
                <li>Pengguna wajib menjaga keamanan akun dan akses akun milik pengguna dari ancaman atau hal-hal merugikan yang mungkin terjadi akibat kelalaian pengguna;</li>
                <li>TraceBit tidak mendukung dan tidak bertanggung jawab atas penggunaan akses oleh pihak ketiga. Apabila timbul kerugian seperti kerusakan dan kehilangan data atas kelalaian pengguna dan/atau sehubungan dengan akses oleh pihak ketiga TraceBit tidak bertanggung jawab, secara langsung maupun tidak langsung;</li>
                <li>Dalam hal Para Pengguna mendaftarkan akun dan memasukan informasi apapun saat menggunakan layanan TraceBit, Pengguna harus berhati-hati dalam mengelola informasi tersebut dengan tanggung jawab mereka masing-masing untuk memastikan bahwa informasi tersebut tidak digunakan dengan cara melawan hukum;</li>
                <li>Pengguna menyetujui untuk dengan segera menginformasikan TraceBit setiap adanya dugaan penggunaan yang tidak sah/valid dengan pengatasnamaan nama akun Pengguna serta memastikan bahwa pengguna telah keluar (<i>logout</i>) dari akun pada setiap akhir dari aktivitas pada situs TraceBit untuk menghindari kemungkinan terjadinya penyalahgunaan atas akun yang bersangkutan.</li>
              </ol>
            </li>
            <li>Pembayaran
              <ol type="a">
                <li>Semua dokumen administrasi yang perlu dikirim dalam bentuk <i>hardcopy</i> ke alamat pengguna, akan dikenakan biaya administrasi sebesar Rp25.000,-  untuk mengganti biaya materai, kirim, dan cetak.</li>
              </ol>
            </li>
            <li>Modifikasi dan Pembaruan
              <ol type="a">
                <li>TraceBit berhak untuk mengubah situs TraceBit setiap saat, dengan atau tanpa pemberitahuan kepada Pengguna. Misalnya, TraceBit dapat menambah atau menghapus fungsionalitas atau fitur, dan dapat menangguhkan atau menghentikan fitur tertentu.</li>
                <li>TraceBit berhak memodifikasi atau mengubah Syarat dan Ketentuan ini. Pengguna disarankan membaca secara seksama dan memeriksa halaman Syarat dan Ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan situs TraceBit, maka Pengguna dianggap menyetujui perubahan-perubahan dalam halaman Syarat dan Ketentuan.</li>
              </ol>
            </li>
            <li>Kekayaan Intelektual
              <ol type="a">
                <li>Menggunakan Situs TraceBit tidak memberi Pengguna kepemilikan atas hak kekayaan intelektual apapun atas konten yang Pengguna akses. Pengguna tidak boleh menggunakan konten dari Situs TraceBit kecuali Pengguna mendapatkan izin dari TraceBit, atau kecuali Pengguna diizinkan oleh hukum;</li>
                <li>TraceBit berhak sepenuhnya untuk membatasi, memblokir atau mengakhiri pelayanan dari suatu akun, melarang akses ke situs TraceBit dan konten, layanan, dan memperlambat atau menghapus <i>hosted content</i>, dan mengambil langkah-langkah hukum untuk menjaga member TraceBit atau pengguna lain jika TraceBit menganggap member TraceBit atau pengguna lain melanggar hukum-hukum yang berlaku, melanggar hak milik intelektual dari pihak terkait, atau melakukan suatu pelanggaran yang melanggar hal-hal yang tertera pada Perjanjian ini;</li>
                <li>Jika Pengguna yakin bahwa konten di layanan melanggar hak cipta Pengguna, Pengguna dapat meminta TraceBit untuk menghapus konten dari layanan (atau menonaktifkan akses ke konten itu) dengan menyertakan bukti sah yang berlaku. Pengguna yang tidak dapat membuktikan Hak atas Kekayaan Intelektual dan atau Surat Ijin Usaha dan atau Akta Perusahaan dalam waktu 5 (lima) hari, maka akun Pengguna tersebut akan dihapus.</li>
              </ol>
            </li>
            <li>Penggunaan Informasi Pengguna
              <ol type="a">
                <li>Data dan informasi yang diberikan kepada TraceBit pada saat pendaftaran dapat dipergunakan secara wajar oleh TraceBit untuk tujuan pemasaran serta analisis data untuk tujuan evaluasi statistik;</li>
                <li>Untuk memberikan atau meningkatkan pengalaman Pengguna di situs TraceBit dari waktu ke waktu, Pengguna mengakui dan menyetujui bahwa TraceBit dapat menahan dan menyimpan informasi Pengguna pada sistem situs ini untuk tujuan pengarsipan terlepas dari pengakhiran atau penonaktifan akun Pengguna;</li>
                <li>TraceBit berhak untuk secara teratur mengirimkan email pemasaran kepada Pengguna untuk fitur atau jasa dari situs TraceBit. TraceBit tidak perlu mendapatkan persetujuan terpisah dari Pengguna untuk ini;</li>
                <li>Apabila pengguna keberatan dengan penggunaan alamat email untuk tujuan pemasaran, Pengguna berhak untuk mengajukan keberatan dengan menginformasikannya melalui email <a href="mailto:support@tracebit.net">support@tracebit.net</a>. Setelah menerima keberatan Pengguna, penggunaan alamat email untuk tujuan pemasaran akan segera dihentikan. Namun apabila Pengguna ingin menolak penggunaan informasi pengguna untuk tujuan analisis data dan evaluasi statistik, Pengguna harus berhenti menggunakan layanan TraceBit.</li>
              </ol>
            </li>
            <li>
              Penyimpanan, keamanan data dan <i>backup</i>
              <br>
              Pengguna bertanggung jawab sepenuhnya untuk melakukan tindakan untuk:
              <ol type="a">
                <li>Mencegah kehilangan, kerusakan data dan konten pada akun Pengguna.</li>
                <li>Membuat salinan, arsip, dan backup pribadi untuk data dan konten Pengguna.</li>
                <li>Memastikan kerahasiaan dan keamanan semua data dan konten Pengguna.</li>
              </ol>
              TraceBit bukan merupakan layanan backup dan tidak memiliki kewajiban kepada Pengguna atas kehilangan, kerusakan data dan konten Pengguna yang diakibatkan oleh hal apapun termasuk <i>force majeure</i>, <i>system failure</i>, <i>hardware failure</i>, dan sebagainya.
            </li>
            <li>Pembatasan Tanggung Jawab TraceBit
              <ol type="a">
                <li>TraceBit akan memberikan pelayanan maksimal kepada Pengguna namun tidak serta merta menjamin bahwa situs ini akan bebas seutuhnya dari <i>bug</i>, <i>virus</i>, gangguan, serangan <i>cyber</i>, dan akibat lain yang ditimbulkan oleh keadaan memaksa/di luar kendali termasuk di antaranya bencana alam maupun non-alam, kebakaran, pemogokan, kerusuhan, perang, terorisme, wabah penyakit, tindakan atau kebijakan pemerintah, gangguan telekomunikasi, pembatasan berdasarkan ketentuan hukum yang berlaku, dan sebagainya (<i>force majeure</i>);</li>
                <li>TraceBit tidak bertanggung jawab atas kerugian atau kerusakan yang disebabkan oleh <i>bug</i>, <i>virus</i>, atau gangguan apapun yang disebabkan oleh penggunaan situs TraceBit oleh Pengguna;</li>
                <li>TraceBit tidak bertanggung jawab atas segala bentuk konten yang digunakan atau diunggah oleh Pengguna ke situs TraceBit;</li>
                <li>TraceBit tidak akan menanggung kesalahan input data dalam bentuk apapun oleh Pengguna yang mengakibatkan informasi tersebut sampai ke pihak yang tidak sesuai;</li>
                <li>TraceBit tidak bertanggung jawab apabila <i>password</i> akun yang digunakan oleh Pengguna terlalu mudah sehingga dapat diakses oleh pihak lain yang tidak berkepentingan;</li>
                <li>TraceBit tidak bertanggung jawab atas kerugian atau kerusakan yang disebabkan oleh kelalaian Pengguna yang berkaitan dengan keamanan akun dan akses oleh pihak ketiga kecuali dapat dibuktikan sebaliknya;</li>
                <li>TraceBit tidak bertanggung jawab atas ketidaktersediaan sementara maupun keterlambatan layanan yang disebabkan oleh peningkatan, modifikasi, pemeliharaan berkala di situs TraceBit, atau hal lain di luar kendali.</li>
              </ol>
            </li>
            <li>
              Masa Penggunaan Akun
              <br>
              Masa penggunaan akun akan terhitung setelah Pengguna berhasil mendaftarkan akun dan  akun menjadi aktif, sampai akun Pengguna ditutup atau dihapus oleh TraceBit dengan alasan tertentu.
            </li>
            <li>
              <ol type="a">
                <li>Pengguna dapat mengajukan penutupan akun kepada TraceBit, namun Pengguna harus memastikan bahwa semua data sudah tersimpan dan tidak ada lagi data yang tertinggal di situs TraceBit;</li>
                <li>Apabila di kemudian hari, TraceBit menemukan aktivitas mencurigakan dalam pemanfaatan fitur-fitur layanan situs ini, maka TraceBit berhak me-non-aktifkan akun untuk tujuan investigasi dan/atau menutup akun Pengguna;</li>
                <li>Apabila di kemudian hari, TraceBit menemukan aktivitas mencurigakan dalam pemanfaatan situs ini untuk tujuan yang tidak sesuai dengan undang-undang yang berlaku baik di Indonesia maupun di negara lainnya, maka TraceBit  berhak men-non-aktifkan  atau menutup akun Pengguna;</li>
                <li>Apabila layanan TraceBit telah berbayar, dan Pengguna tidak melakukan pembayaran pada waktu yang telah ditentukan, Pengguna tidak dapat mengakses akun TraceBit dan data Pengguna akan hilang;</li>
                <li>TraceBit berhak menghapus akun dan semua data dari akun yang tidak aktif selama lebih dari 6 bulan;</li>
                <li>Dalam hal penghapusan akun dan data Pengguna yang tidak aktif, TraceBit akan memberikan peringatan penghapusan kepada Pengguna dalam: 1 (satu) bulan sebelum waktu berakhir; 7 (tujuh) hari sebelum waktu berakhir; dan 1 (satu) hari sebelum waktu berakhir.</li>
              </ol>
            </li>
            <li>
              Pengalihan
              <br>
              Pengguna tidak dapat mengalihkan hak dan kewajiban dalam Syarat dan Ketentuan layanan ini tanpa persetujuan tertulis terlebih dahulu dari TraceBit, namun TraceBit berhak untuk mengalihkan hak dan kewajiban TraceBit kepada pihak lain setiap saat tanpa memerlukan persetujuan tertulis dari Pengguna, dan tanpa kewajiban memberikan pemberitahuan terlebih dahulu kepada Pengguna.
            </li>
            <li>Informasi Pelanggan
              <ol type="a">
                <li>Setiap saran, komentar, permintaan, keluhan atau pertanyaan terkait dengan layanan TraceBit dapat diajukan melalui <a href="mailto:support@tracebit.net">support@tracebit.net</a>;</li>
                <li>TraceBit berhak untuk menolak, memproses atau merespon saran, permintaan, keluhan atau pertanyaan Pengguna apabila data atau informasi Pengguna tidak sesuai dengan yang tertera di sistem TraceBit.</li>
              </ol>
            </li>
            <li>
              Yurisdiksi Hukum
              <br>
              Syarat dan Ketentuan ini diatur dan ditafsirkan berdasarkan hukum negara Republik Indonesia
            </li>
          </ol>
          <p>Pembaruan terakhir: 1 Oktober 2022</p>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BLink, BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
    BCard,
  },
}
</script>

<style>
  .navbar .navbar-menu {
    margin: 0 !important;
    max-height: 100%;
    min-width: 0;
    width: 100% !important;
    z-index: 1 !important;
  }
  .header-navbar {
    position: fixed;
    height: 4.45rem;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }
  .navbar-brand {
    padding: 0.5rem 0;
  }
  .brand-logo img {
    max-height: 30px;
    max-width: 100% !important;
  }
  .nav-right {
    float: right;
  }
  .nav-right a {
    display: flex;
    align-items: center;
    padding: 0rem 16px;
    color: #505050;
    font-size: 1.2rem;
  }
  .nav-right a:hover {
    color: #1b4694;
  }
  ul.dash{
    padding-right: 1em;
    list-style-type:none;
    list-style-position:outside;
  }
  ul.dash li {
    padding-left: 1em;
    position: relative;
  }
  ul.dash li:before{
    content: '-';
    position: absolute;
    left: 0;
  }
</style>
